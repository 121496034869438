import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
// 登录验证
export default new Vuex.Store({
    state: {
        userMsg: {},
        dataNeedsRefresh: false,
    },
    mutations: {
        setDataNeedsRefresh(state, payload) {
            state.dataNeedsRefresh = payload;
        },
    }
})
